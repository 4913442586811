var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-roles-table","fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"icon":"mdi-account-key","title":_vm.$t('roles and permissions.table.roles title')}},[_c('roles-form',{ref:"editForm",attrs:{"data":_vm.meta},on:{"itemSaved":_vm.onSaved}}),_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('roles and permissions.delete role')},on:{"itemDeleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_vm._v(" Are you sure you want to delete role "),_c('strong',[_vm._v(_vm._s(item.name))]),_vm._v("? ")]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(" "+_vm._s(error.message)+" ")]):_vm._e()]}},{key:"confirmed",fn:function(item){return [(item.id)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v("Role "+_vm._s(item.name)+" deleted")]):_vm._e()]}}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"multi-sort":"","footer-props":{
        'items-per-page-options': [10, 20, 50],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [(item.isNew)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("roles and permissions.add role"))+" ")],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.permissions",fn:function(ref){
      var item = ref.item;
return _vm._l((item.permissions),function(permission){return _c('v-chip',{key:'permission-' + item.id + '-' + permission,attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.meta[permission].name)+" ")])})}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }